import classNames from 'classnames/bind';
import { graphql } from 'gatsby';
import React from 'react';
import DoubleColCTA from '../../components/About/DoubleColCTA/DoubleColCTA';
import PictoDetails from '../../components/About/PictoDetails/PictoDetails';
import TripleCol from '../../components/About/TripleCol/TripleCol';
import Locations from '../../components/common/Locations/Locations';
import PushNavigation from '../../components/common/PushNavigation/PushNavigation';
import TextBubble from '../../components/common/TextBubble/TextBubble';
import Video from '../../components/common/Video/Video';
import Intro from '../../components/Intro/Intro';
import Seo from '../../components/Seo/Seo';
import * as styles from './About.module.scss';

const c = classNames.bind(styles);

export const query = graphql`
	query($id: String!, $lang: WpLanguageCodeEnum) {
		page: wpPage(id: { eq: $id }, language: { code: { eq: $lang } }) {
			seo {
				title
				metaDesc
				opengraphTitle
				opengraphDescription
				opengraphSiteName
				opengraphImage {
					uri
				}
				twitterTitle
				twitterDescription
				twitterImage {
					uri
				}
			}
			translations {
				uri
				language {
					locale
				}
			}
			acf: acfPageAbout {
				header {
					bgtype
					description
					title
					image {
						filesize
						height
						mime_type
						title
						url
						width
					}
					image_mobile {
							filesize
							height
							mime_type
							title
							url
							width
					}
					cover {
						filesize
						height
						mime_type
						title
						url
						width
					}
					vimeoId: popin_video
					video {
						filesize
						mime_type
						subtype
						title
						type
						url
					}
				}
				locations {
					items {
						address
						country
					}
				}
				video {
					image {
						url
						title
					}
					link
				}
				manifesto {
					title_manifesto
					items {
						title
						description
						image {
							url
							title
						}
					}
				}
				salesforce_consulting {
					items {
						headline
						text
						title
						page {
							title
							url
						}
					}
				}
				text_bubble {
					color
					headline
					text
					title
				}
				text_bubble2 {
					color
					headline
					text
					title
				}
				engagements {
					items {
						image {
							title
							url
						}
						description
						title
					}
				}
			}
		}
	}
`;

export default function About({ data }) {
	const { seo, translations, acf } = data.page;
	return (
		<main className={c('wrapper')}>
			<Seo {...{ seo, translations }} />
			<Intro data={acf.header} />
			<TextBubble data={acf.text_bubble} />
			<PictoDetails data={acf.manifesto} />
			<DoubleColCTA items={acf.salesforce_consulting.items} />
			<Locations items={acf.locations.items} />
			{acf.video.link && <Video data={acf.video} />}
			<TextBubble data={acf.text_bubble2} />
			<TripleCol items={acf.engagements.items} />
			<PushNavigation className={c('about')} />
		</main>
	);
}
