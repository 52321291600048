// extracted by mini-css-extract-plugin
export var about = "PushNavigation-module--about--HHlcf";
export var button = "PushNavigation-module--button--B8SF5";
export var containerButton = "PushNavigation-module--containerButton--0N7IB";
export var cta = "PushNavigation-module--cta--0yjPW";
export var divObserver = "PushNavigation-module--divObserver--nCJD+";
export var flexible = "PushNavigation-module--flexible--HkPnO";
export var home = "PushNavigation-module--home--+UDna";
export var orange = "PushNavigation-module--orange--t9HjK";
export var show = "PushNavigation-module--show--AAY1b";
export var title = "PushNavigation-module--title--0VnR5";
export var wrapper = "PushNavigation-module--wrapper--2jxuY";