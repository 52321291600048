import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import React from 'react';
import Dash from '../../common/Dash/Dash';
import * as styles from './DoubleColCTA.module.scss';

const c = classNames.bind(styles);

function DoubleColCTA({ className, items }) {
	return (
		<section className={c('wrapper', className)}>
			{items && items.map((item, i) => {
				const url = item.page?.url && new URL(item.page?.url).pathname;
				return (
					<section className={c('section')} key={i}>
						<p className={c('label')}>{item.headline}</p>
						<h2 className={c('title')}>{item.title}</h2>

						<div
							className={c('text', 'first')}
							dangerouslySetInnerHTML={{ __html: item.text }}
						/>

						<Dash className={c('dash')} />

						<Link to={url} className={c('cta', { orange: i > 0 })}>
							{item.page?.title.toString()}
						</Link>
					</section>
				);
			})}
		</section>
	);
}

export default DoubleColCTA;