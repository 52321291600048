import classNames from 'classnames/bind';
import React from 'react';
import Bubble from '../Bubble/Bubble';
import Dash from '../Dash/Dash';
import * as styles from './TextBubble.module.scss';

const c = classNames.bind(styles);

function TextBubble({ className, data }) {
	return (
		<section className={c('wrapper', className)}>
			<div className={c('texts')}>
				<p className={c('label')}>{data.headline}</p>
				<h2
					className={c('title')}
					dangerouslySetInnerHTML={{ __html: data.title }}
				></h2>
				<p
					className={c('text')}
					dangerouslySetInnerHTML={{ __html: data.text }}
				/>
				<Dash className={c('dash')} />
			</div>
			<Bubble className={c('bubble')} color={data.color} />
		</section>
	);
}

export default TextBubble;
