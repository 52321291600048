import classNames from 'classnames/bind';
import { graphql, useStaticQuery } from "gatsby";
import React, { useContext } from 'react';
import { LanguageContext } from '../../../../utils/LanguageContext';
import * as styles from './ButtonPlay.module.scss';

const c = classNames.bind(styles);

function ButtonPlay({ className, playVideo, ref }) {
	const language = useContext(LanguageContext);
	const query  = useStaticQuery(graphql`
		query {
			wp {
				sp {
					strings {
						video_play_label {
							en
							fr
						}
					}
				}
			}
		}
	`)
	const strings = query.wp.sp.strings.video_play_label;
	return (
		<button 
			className={c('wrapper', className)} 
			onClick={playVideo} 
			ref={ref}
		>
			<span className={c('text')}>
				{
					language.languageCode === "FR" 
						? strings.fr
						: strings.en
				}
			</span>
			<div className={c('round')}></div>
		</button>
	);
}

export default ButtonPlay;
