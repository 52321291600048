/* eslint-disable */
import classNames from 'classnames/bind';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import { InView } from 'react-intersection-observer';
import getPageURL from '../../../utils/getPageURL';
import { LanguageContext } from "../../../utils/LanguageContext";
import BigButton from '../BigButton/BigButton';
import * as styles from './PushNavigation.module.scss';

const c = classNames.bind(styles);

// Type : Flexible, Home, About
function PushNavigation({ className, type, i, indexStrate, setIndexStrate }) {
	const language = useContext(LanguageContext);
	const query = useStaticQuery(graphql`
		query {
			salesforce: allWpPage(
				filter: { acfPageListing: { type: { eq: "salesforce" } } }
			) {
				nodes {
					uri
					language {
						code
					}
				}
			}
			consulting: allWpPage(
				filter: { acfPageListing: { type: { eq: "consulting" } } }
			) {
				nodes {
					uri
					language {
						code
					}
				}
			}
			work: allWpPage(
				filter: { acfPageListing: { type: { eq: "work" } } }
			) {
				nodes {
					uri
					language {
						code
					}
				}
			}
		}
	`);
	const title =
		type === 'flexible'
			? 'Explorez<br> nos expertises'
			: 'Explorez<br> nos réalisations';


	return (
		<section
			className={c('wrapper', className, type, { show: indexStrate === 5 })}>
			<h2 className={c('title')} dangerouslySetInnerHTML={{ __html: title }} />

			<div className={c('containerButton')}>
				{type === 'flexible' ? (
					<>
						<Link 
							to={getPageURL(query.salesforce, language.languageCode)} 
							className={c('cta')}
							>
							L'expertise technique
						</Link>

						<Link 
							to={getPageURL(query.consulting, language.languageCode)} 
							className={c('cta', 'orange')}
							>
							L'expertise métier
						</Link>
					</>
				) : (
					<BigButton
						to={getPageURL(query.work, language.languageCode)}
						className={c('button')}
					/>
				)}
			</div>
			{ indexStrate && <InView
				className={c('divObserver')}
				rootMargin='100px'
				onChange={(inView) => {
					inView ? setIndexStrate(5) : null;
				}} /> }
		</section>
	);
}

export default PushNavigation;
