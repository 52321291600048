import classNames from 'classnames/bind';
import React from 'react';
import Dash from '../../common/Dash/Dash';
import Picto from '../../common/Picto/Picto';
import * as styles from './PictoDetails.module.scss';

const c = classNames.bind(styles);

function PictoDetails({ data }) {
	return (
		<section className={c('wrapper')}>
			<h2 className={c('label')}>{data.title_manifesto}</h2>
			<ul className={c('items')}>
				{data.items.map((item, i) => (
					<li key={i} className={c('item')}>
						<Picto image={item.image} className={c('picto')} index={i} />
						<div className={c('texts')}>
							<h3 className={c('label', 'title')}>{item.title}</h3>
							<p
								className={c('text')}
								dangerouslySetInnerHTML={{ __html: item.description }}
							/>
							<Dash />
						</div>
					</li>
				))}
			</ul>
		</section>
	);
}

export default PictoDetails;
