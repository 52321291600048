import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import React from 'react';
import * as styles from './BigButton.module.scss';

const c = classNames.bind(styles);

function BigButton({ className, to }) {
	return (
		<Link to={to} className={c('wrapper', className)}>
			<div className={c('dot')}/>
			<div className={c('dot')}/>
		</Link>
	);
}

export default BigButton;
