import classNames from 'classnames/bind';
import React from 'react';
import * as styles from './Bubble.module.scss';

const c = classNames.bind(styles);

function Bubble({ className, color }) {
	return (
		<div className={c('wrapper', className)}>
			<picture>
				<source
					srcSet={`/images/spheres/bubble-${color ? color : 'blue'}.webp`}
					type="image/webp"
				/>
				<source
					srcSet={`/images/spheres/bubble-${color ? color : 'blue'}.png`}
					type="image/png"
				/>
				<img
					alt="Bubble"
					loading="lazy"
					src={`/images/spheres/bubble-${color ? color : 'blue'}.png`}
				/>
			</picture>
		</div>
	);
}

export default Bubble;
