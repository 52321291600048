import classNames from 'classnames/bind';
import React from 'react';
import Dash from '../../common/Dash/Dash';
import * as styles from './TripleCol.module.scss';

const c = classNames.bind(styles);

function TripleCol({ items }) {
	return (
		<section className={c('wrapper')}>
			{items.map((item, i) => (
				<section className={c('item')} key={i}>
					<img
						loading="lazy"
						src={item.image.url}
						alt={item.image.title}
						className={c('image')}
					/>
					<div className={c('texts')}>
						<h3
							className={c('label')}
							dangerouslySetInnerHTML={{
								__html: item.title.replace('.', '.<br>'),
							}}
						/>
						<Dash className={c('dash')} />
						<p
							className={c('text')}
							dangerouslySetInnerHTML={{ __html: item.description }}
						/>
					</div>
				</section>
			))}
		</section>
	);
}

export default TripleCol;
