import classNames from 'classnames/bind';
import React, { useRef, useState } from 'react';
import ButtonPlay from './ButtonPlay/ButtonPlay';
import * as styles from './Video.module.scss';

const c = classNames.bind(styles);

function Video({ className, data }) {
	const video = useRef(null);

	const [show, setShow] = useState(true);
	const [autoplay, setAutoplay] = useState('');

	const playVideo = () => {
		setAutoplay('?autoplay=1');
		setTimeout(() => {
			setShow(false);
		}, 500);
	};
	return (
		<section className={c('wrapper', className)}>
			{show && (
				<div
					className={c('poster')}
					style={{ backgroundImage: `url("${data.image.url}")` }}
				>
					{' '}
				</div>
			)}
			<div className={c('containerVideo')}>
				<iframe
					title="video"
					src={'https://player.vimeo.com/video/' + data.link + autoplay}
					className={c('video')}
					frameBorder="0"
					ref={video}
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen
				/>
			</div>
			{show && <ButtonPlay playVideo={playVideo} />}
		</section>
	);
}

export default Video;
